import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['control']

  connect() {
    this.containIframe()
  }

  // Impossible method - no idea where it gets event from here,
  // but used by the search bar somehow.
  submitForm() {
    Rails.fire(event.currentTarget.form, 'submit')
  }

  addWaiting() {
    let waiting = document.createElement('div')
    waiting.classList.add('enquiry__spinner')
    this.controlTarget.append(waiting)
  }

  toggleComponent(e) {
    let toggle = e.currentTarget.nextElementSibling
    if(toggle.classList.contains('open')) {
      this.closeToggle(toggle)
    } else {
      this.closeAllToggles()
      this.openToggle(toggle)
    }
  }

  toggleComponentWithSave(e) {
    let toggle = e.currentTarget.nextElementSibling

    if(toggle.classList.contains('open')) {
      toggle.querySelector("#save-on-close").click()
    } else {
      this.closeAllToggles()
      this.openToggle(toggle)
    }
  }

  closeAllToggles() {
    document.querySelectorAll('.enquiry__control-wrap.open')
      .forEach(toggle => toggle.classList.remove('open'));
  }

  closeToggle(toggle) {
    toggle.classList.remove('open')
    if (this.noOpenToggles()) {
      this.enablePricelist()
    }
    this.containIframe()
  }

  openToggle(toggle) {
    toggle.classList.add('open')
    this.disablePricelist()
    this.overflowIframe()
  }

  noOpenToggles() {
    let enquiry_toggles = document.getElementsByClassName("enquiry__control-wrap open")
    return enquiry_toggles.length < 1
  }

  containIframe() {
    const event = new Event('containIframe')
    document.body.dispatchEvent(event)
  }

  overflowIframe() {
    const event = new Event('overflowIframe')
    document.body.dispatchEvent(event)
  }

  disablePricelist() {
    var price_lists = document.getElementsByClassName("js-enquiry-price-list__wrap")

    for(var i = 0; i < price_lists.length; i++) {
      price_lists[i].classList.add('disabled');
    }
  }

  enablePricelist() {
    var price_lists = document.getElementsByClassName("js-enquiry-price-list__wrap")

    for(var i = 0; i < price_lists.length; i++) {
      price_lists[i].classList.remove('disabled');
    }
  }

  removeSecretCode() {
    const form = document.querySelector('.js-secret-code form');
    if (form) {
      Rails.fire(form, 'submit');
    }
  }
}
