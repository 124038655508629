import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'sourceStartDate',
    'sourceEndDate',
    'destinationStartDate',
    'destinationEndDate',
    'modify',
    'modifierWrap'
  ]

  connect() {
    [
      this.sourceStartDateTarget,
      this.sourceEndDateTarget,
      this.destinationStartDateTarget
    ].forEach(element => {
      $(element).on('changeDate', this.updateEndDate.bind(this))
    })
    this.modify()
  }

  modify() {
    let style = this.doesModify ? 'block' : 'none'
    this.modifierWrapTarget.style.display = style
  }

  updateEndDate(event) {
    let moment = this.destinationStart(event)
    if (moment.isValid()) {
      this.destinationEndDateTarget.value = this.destinationEndDate(moment)
    }
  }

  destinationEndDate(moment) {
    return moment.add(this.sourceDays, 'days').format('DD/MM/YYYY')
  }

  destinationStart(event) {
    if (event.target == this.destinationStartDateTarget) {
      return moment(event.date)
    } else {
      return moment(this.destinationStartDateTarget.value, 'DD/MM/YYYY')
    }
  }

  get sourceDays() {
    return this.sourceEnd.diff(this.sourceStart, 'days')
  }

  get sourceStart() {
    return moment(this.sourceStartDateTarget.value, 'DD/MM/YYYY')
  }

  get sourceEnd() {
    return moment(this.sourceEndDateTarget.value, 'DD/MM/YYYY')
  }

  get doesModify() {
    return JSON.parse(this.modifyTargets.find(input => input.checked).value)
  }
}
